import { MenuItem } from './MenuItem'

export function MenuInner() {
  return (
    <>
      <MenuItem title={'Users'} to='/users' />
      <MenuItem title='Companies' to='/companies' />
      <MenuItem title='Logs' to='/logs' />
      <MenuItem title='Templates' to='/templates' />
    </>
  )
}
