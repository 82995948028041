import { SortModelItem } from 'ag-grid-community';
import { ISimpleFilterModelType } from 'ag-grid-community/dist/types/core/filter/provided/simpleFilter';
import { compact, isNil } from 'lodash';
import { ValueOf } from 'type-fest';
import {GridSortItem} from '../interfaces/response-fornat.model';

export const convertGridSortToQueryParams = (sort: SortModelItem[]): string[] => {
	let converted = sort.map(col => {
		if (isNil(col.sort)) {
			return null;
		}

		return col.sort === 'desc' ? `-${col.colId}` : col.colId;
	});
	return compact(converted);
};

export const convertGridSortToSecchiSort = <T>(sort: SortModelItem[]): GridSortItem<T>[] => {
	let converted = sort.map((col, i) => {
		if (isNil(col.sort)) {
			return null
		}

		return {
			dir: col.sort,
			field: (col.colId as unknown as T),
			position: i,
		}
	})
	return compact(converted)
}

// TODO: ag-grid marks the filter stuff as `any`, so they're of no help
export interface AgGridFilterItem {
	filter: string;
	filterType: 'text',
	type: 'contains',
}

export interface AgGridFilterDateItem {
	dateFrom: string;
	dateTo: string;
	filterType: 'date';
	type: AgGridFilterModelTypeSupported;
}

// ISimpleFilterModelType [from ag-grid]
// export type ISimpleFilterModelType = 'empty' |
// 'equals' |
// 'notEqual' |
// 'lessThan' |
// 'lessThanOrEqual' |
// 'greaterThan' |
// 'greaterThanOrEqual' |
// 'inRange' |
// 'contains' |
// 'notContains' |
// 'startsWith' |
// 'endsWith' |
// 'blank' |
// 'notBlank';

// 'LIKE' | 'GREATER_THAN' | 'LESS_THAN' | 'EQUALS',
export enum AgGridFilterTypeToSecchiOperators {
	contains = 'LIKE',
	equals = 'EQUALS',
	greaterThan = 'GREATER_THAN',
	lessThan = 'LESS_THAN',
	inRange = 'BETWEEN',
}

// 'LIKE' | 'GREATER_THAN' | 'LESS_THAN' | 'EQUALS',
export enum AgGridFilterTypeMapToOperator {
	contains = 'LIKE',
	equals = 'EQUALS',
	greaterThan = 'GREATER_THAN',
	lessThan = 'LESS_THAN',
	inRange = 'BETWEEN',
}

export enum QueryOperators {
	Like = 'LIKE',
	Equals = 'EQUALS',
	GreaterThan = 'GREATER_THAN',
	LessThan = 'LESS_THAN',
	Between = 'BETWEEN',
}

export type AgGridToQueryOperators = {
	'equals': QueryOperators.Equals,
	inRange: QueryOperators.Between,
	lessThan: QueryOperators.LessThan,
	greaterThan: QueryOperators.GreaterThan,
	contains: QueryOperators.Like,
}

export enum AgGridOperators {
	like = 'LIKE'
}

export type OperatorMapTest = {
	[Property in keyof Extract<ISimpleFilterModelType, 'equals' | 'inRange' | 'lessThan' | 'greaterThan' | 'contains'>]: AgGridFilterTypeToSecchiOperators[Property];
}

export type SecchiOperators = ValueOf<AgGridFilterTypeToSecchiOperators>;

export type AgGridFilterModelTypeSupported = Extract<ISimpleFilterModelType, 'equals' | 'inRange' | 'lessThan' | 'greaterThan' | 'contains'>;
