import {
	GridFilterItem,
	GridSortItem,
	SkeResponseWithManyPaginatedResults,
} from '../../common/interfaces/response-fornat.model';
import {LogFilterableFields, LogModel, LogSortableFields} from './interfaces/log.model';
import axios, {AxiosResponse} from 'axios';
import {LOGS_URL} from '../../common/consts/api.const';

export interface GetLogPayload {
	pageNumber: number;
	pageSize: number;
	fromDate?: string;
	toDate?: string;
	sort?: GridSortItem<LogSortableFields>[];
	filters?: GridFilterItem<LogFilterableFields>[];
	detailed?: boolean;
	deleted?: boolean;
}

export function getLogs(token: string,
															 opts: GetLogPayload): Promise<AxiosResponse<SkeResponseWithManyPaginatedResults<LogModel>>> {
	console.log("OPTS ", opts)
	return axios.post<SkeResponseWithManyPaginatedResults<LogModel>>(LOGS_URL, opts,
		{
			headers: {
				'Authorization': `Bearer ${token}`,
			},
		});
}