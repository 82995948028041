import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import {
	CellClickedEvent,
	ColDef,
	GridOptions,
	RowClickedEvent,
	RowDoubleClickedEvent,
	SelectionChangedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Ref, useMemo } from 'react';

interface GridProps<T> {
	cols: ColDef<T>[];
	rowData?: T[];
	defaultColDef?: ColDef;
	rowSelection?: 'single' | 'multiple';
	suppressCellFocus?: boolean;
	multiSelectWithClick?: boolean;
	onSelectionChanged?: (event: SelectionChangedEvent<T>) => void;
	onRowClicked?: (event: RowClickedEvent<T>) => void;
	onCellClicked?: (event: CellClickedEvent<T>) => void;
	onRowDoubleClicked?: (event: RowDoubleClickedEvent<T>) => void;
	gridRef?: Ref<AgGridReact>;
	gridOptions?: GridOptions<T>;
	gridWidth?: number | string;
	gridHeight?: number | string;
	rowModelType?: 'clientSide' | 'infinite' | 'serverSide' | 'viewport';
	cacheBlockSize?: number;
	cacheOverflowSize?: number;
	maxConcurrentDatasourceRequests?: number;
	maxBlocksInCache?: number;
	pagination?: boolean;
}

export function Grid<T>({
													rowData,
													cols,
													defaultColDef =  {
														editable: false,
														flex: 1,
														filter: true,
														autoHeight: true,
														floatingFilter: true
													},
													rowSelection,
													suppressCellFocus = true,
													multiSelectWithClick = false,
													onSelectionChanged,
													onRowClicked,
													onCellClicked,
													onRowDoubleClicked,
													gridRef,
													gridOptions,
													gridWidth = '400px',
													gridHeight = '100%',
													rowModelType,
													cacheBlockSize,
													cacheOverflowSize,
													maxConcurrentDatasourceRequests,
													maxBlocksInCache,
													pagination,
												}: GridProps<T>) {
	return (
		<>
			<div
				className="ag-theme-quartz ag-theme-acmecorp"
				style={{
					height: gridHeight,
					width: gridWidth,
				}}
			>
				<AgGridReact
					rowData={rowData}
					columnDefs={cols}
					defaultColDef={defaultColDef}
					rowSelection={rowSelection}
					suppressCellFocus={suppressCellFocus}
					rowMultiSelectWithClick={multiSelectWithClick}
					onSelectionChanged={onSelectionChanged}
					onRowClicked={onRowClicked}
					onCellClicked={onCellClicked}
					onRowDoubleClicked={onRowDoubleClicked}
					ref={gridRef}
					gridOptions={gridOptions}
					rowModelType={rowModelType}
					cacheBlockSize={cacheBlockSize}
					cacheOverflowSize={cacheOverflowSize}
					maxConcurrentDatasourceRequests={maxConcurrentDatasourceRequests}
					maxBlocksInCache={maxBlocksInCache}
					pagination={pagination}
					// suppressRowClickSelection={true}
				></AgGridReact>
			</div>
		</>
	);
}
