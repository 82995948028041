export const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/me`
export const LOGIN_URL = `${API_URL}/auth/signin`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const USERS_URL = `${API_URL}/users`
export const COMPANIES_URL = `${API_URL}/companies`
// export const ACTIONS_URL = `${API_URL}/actions`
export const INVITE_SUPERADMIN_URL = `${API_URL}/auth/invite-superadmin`
export const SEND_INVITE_SUPERVISOR_URL = `${API_URL}/auth/invite-supervisor`
export const SEND_FORGOT_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const AUTOMATIONS_URL = `${API_URL}/automations`
export const ATTENDANCE_REASONS_URL = `${API_URL}/attendance-reasons`
export const ATTENDANCE_RULES_URL = `${API_URL}/attendance-rules`
export const ATTENDANCE_AUTOMATIONS_URL = `${API_URL}/attendance-automations`
export const COACHING_CATEGORIES_URL = `${API_URL}/coaching-categories`
export const COACHING_URL = `${API_URL}/coachings`
export const RECOGNITION_CATEGORIES_URL = `${API_URL}/recognition-categories`
export const RECOGNITION_URL = `${API_URL}/recognitions`
export const DISCIPLINE_STEPS_URL = `${API_URL}/discipline-steps`
export const CATEGORIES_URL = `${API_URL}/category`
export const TEMPLATES_URL = `${API_URL}/templates`
export const LOGS_URL = `${API_URL}/logs/search`;
