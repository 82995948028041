import {ColDef, FilterModel, GridOptions, IGetRowsParams, SortModelItem, ValueGetterParams} from 'ag-grid-community';
import {AgGridReact} from 'ag-grid-react';
import {get, isNil, reduce, toPairs, trim} from 'lodash';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import ReactJson from 'react-json-view';
import {shallowEqual, useSelector} from 'react-redux';
import {generatePath, Link, useHref, useLocation, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {PartialDeep} from 'type-fest';
import {boolean, string} from 'yup';
import {Grid} from '../../../common/components/grid';
import {DateFormatUsingDateFns} from '../../../common/consts/DateFormat.const';
import {GridFilterItem} from '../../../common/interfaces/response-fornat.model';
import {
	AgGridFilterModelTypeSupported,
	AgGridFilterTypeToSecchiOperators,
	convertGridSortToSecchiSort,
	QueryOperators,
} from '../../../common/utilities/ag-grid.utility';
import {parseDateObjectToNewFormat} from '../../../common/utilities/date.utility';
import {RootState} from '../../../setup';
import {
	LogFilterableFields,
	LogModel,
	LogSortableFields,
	SkeCrudAbbr,
	SkeCrudTypeFromAbbr, SkeLogType,
} from '../interfaces/log.model';
import {GetLogPayload, getLogs} from '../LogCRUD';
import CopyToClipboard from 'react-copy-to-clipboard';


const fetchData = async (token: string, newPageNum: number, sort: SortModelItem[], filter: FilterModel) => {
	const parsedFilters: GridFilterItem<LogFilterableFields>[] = [];
	toPairs(filter).map(([field, cfg]) => {
		let value = cfg.filter;
		const type = AgGridFilterTypeToSecchiOperators[(cfg.type as AgGridFilterModelTypeSupported)];

		if (cfg.filterType === 'date') {
			const from = parseDateObjectToNewFormat(new Date(cfg.dateFrom), DateFormatUsingDateFns.PerfectDate);
			const to = parseDateObjectToNewFormat(new Date(cfg.dateTo), DateFormatUsingDateFns.PerfectDate);

			switch (type) {
				case AgGridFilterTypeToSecchiOperators.lessThan:
				case AgGridFilterTypeToSecchiOperators.greaterThan:
					value = from;
					break;
				case AgGridFilterTypeToSecchiOperators.inRange:
					value = {
						from,
						to,
					};
					break;
				case AgGridFilterTypeToSecchiOperators.equals:
				case AgGridFilterTypeToSecchiOperators.contains:
					value = from;
					break;
				default:
					break;
			}
		}
		parsedFilters.push({
			// TODO fixup log
			operator: (type as any),
			value,
			field: (field as unknown as LogFilterableFields),
		});
	});
	const opts: GetLogPayload = {
		pageNumber: newPageNum,
		sort: convertGridSortToSecchiSort<LogSortableFields>(sort),
		filters: parsedFilters,
		pageSize: 100,
		detailed: true,
	};
	return getLogs(token, opts);
};

export function LogGrid(){
	const { quickLink} = useParams();
	const quickLinkRef = useRef<string | null>(null);
	const urlRef = useRef<string | null>(null);
	const gridRef = useRef<AgGridReact<LogModel>>(null);
	const token: string = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual) as string;
	const [selectedLog, setSelectedLog] = useState<LogModel | null>(null);
	const [hideFalsy, setHideFalsy] = useState<boolean>(true);
	const [filteredSelectedLog, setFilteredSelectedLog] = useState<PartialDeep<LogModel>>();
	const location = useLocation();
	const [fullUrlQuickLink, setFullUrlQuickLink] = useState<string>('');
	const [fullUrlDeepLogQuickLink, setFullUrlDeepLogQuickLink] = useState<string>('');

	const filterFalsy = (log: LogModel): PartialDeep<LogModel> => {
		const filt = reduce(
			log,
			(acc, value, key) => {
				if (isNil(value) || value === '') {
					return acc;
				}
				return {
					...acc,
					[key]: value,
				};
			},
			{},
		);
		return filt;
	}

	const handleSetSelectedLog = (log: LogModel) => {
		setSelectedLog(log);
		setFilteredSelectedLog(filterFalsy(log));
		const thisLink = getLink(log.log_type, log.log_id);
		setFullUrlQuickLink(thisLink);
		console.log("SELECTED: ", thisLink, log);
		if (log.log_deep_id) {
			const deepLink = getLink(SkeLogType.Deep, log.log_deep_id);
			console.log('need to link to deep log', deepLink, log);
			setFullUrlDeepLogQuickLink(deepLink);
		} else {
			setFullUrlDeepLogQuickLink('');
		}
	}

	const getLink = (logType: SkeLogType, id: number | string) => {
		return generatePath(`${urlRef.current}/${logType}_${id}`, {relative: 'route'});
	}

	useEffect(() => {
		// console.log('quicklink', quickLink, );
		if (quickLink && quickLinkRef.current !== quickLink) {
			if (!quickLink && quickLinkRef.current !== null) {
				setSelectedLog(null);
				setFilteredSelectedLog(undefined);
				gridRef.current?.api?.refreshInfiniteCache();
			}
			// gridRef.current?.api.setFilterModel()
			quickLinkRef.current = quickLink;
		}

	}, [quickLink]);

	useEffect(() => {
		const href = window.location.href;
		const endPoint = quickLink ? href.substring(0, href.indexOf("logs/") + 4) : href;
		// const endPoint = window.location.href.substr(0, t.lastIndexOf("\\") + 1);
		urlRef.current = endPoint;
		console.log('location', endPoint, href);
	}, [location]);

	const gridOptions: GridOptions = {
		paginationPageSizeSelector: false,
		datasource: {
			rowCount: undefined,
			getRows: async function(params: IGetRowsParams) {
				// https://www.ag-grid.com/javascript-data-grid/infinite-scrolling/#datasource-interface
				let pageNum = Math.round(params.endRow / 100);

				let filter = params.filterModel;
				if (quickLink) {
					// filter = [{
					// 	field: 'quick_link',
					// 	value: quickLink,
					// 	operator: QueryOperators.Equals
					// }];
					filter['quick_link'] = {
						filter: quickLink,
						filterType: 'text',
						type: 'equals',
					};
					console.log('overrode filter based on quick link', filter);
				}


				await fetchData(token, pageNum, params.sortModel, filter)
					.then(({ data }) => {
						if (data.error) {
							throw new Error(data.msg);
						}
						console.log("DATA", quickLink, data);
						const rowsThisPage = data.results.items;
						const lastRow = data.results.pagination.totalItems;
						if (quickLink && data.results.items.length === 1) {
							console.log('quickLink', data.results.items[0]);
							handleSetSelectedLog(data.results.items[0]);
						}
						params.successCallback(rowsThisPage, lastRow);
					})
					.catch(err => {
						toast.error(err.message);
					});
			},
		},
	};
	const [columnDefs, setColumnDefs] = useState<ColDef<LogModel>[]>([
		{
			field: 'company_name',
			headerName: 'Company',
			enableRowGroup: true,
			suppressHeaderMenuButton: true,
			filter: 'agTextColumnFilter',
			filterParams: {
				filterOptions: [
					'contains',
					'equals',
				],
			},
		},
		{
			field: 'created_at',
			headerName: 'Created',
			enableRowGroup: true,
			suppressHeaderMenuButton: true,
			filter: 'agTextColumnFilter',
			filterParams: {
				filterOptions: [
					'contains',
					'equals',
				],
			},
		},
		{
			field: 'crud',
			headerName: 'Action',
			enableRowGroup: true,
			suppressHeaderMenuButton: true,
			filter: 'agTextColumnFilter',
			valueGetter: (params: ValueGetterParams<LogModel, SkeCrudAbbr>) => {
				// console.log('params', params.data);
				const crud = trim(get(params, 'data.crud', ''));
				// @ts-ignore
				return SkeCrudTypeFromAbbr[crud] || '';
			},
			filterParams: {
				filterOptions: [
					'contains',
					'equals',
				],
			},
		},
		{
			field: 'domain',
			headerName: 'Feature',
			enableRowGroup: true,
			suppressHeaderMenuButton: true,
			filter: 'agTextColumnFilter',
			filterParams: {
				filterOptions: [
					'contains',
					'equals',
				],
			},
		},
		{
			field: 'subdomain',
			headerName: 'Sub Feature',
			enableRowGroup: true,
			suppressHeaderMenuButton: true,
			filter: 'agTextColumnFilter',
			filterParams: {
				filterOptions: [
					'contains',
					'equals',
				],
			},
		},
		{
			field: 'handler',
			headerName: 'Method',
			enableRowGroup: true,
			suppressHeaderMenuButton: true,
			filter: 'agTextColumnFilter',
			filterParams: {
				filterOptions: [
					'contains',
					'equals',
				],
			},
		},
		{
			field: 'log_type',
			headerName: 'Type',
			enableRowGroup: true,
			suppressHeaderMenuButton: true,
			filter: 'agTextColumnFilter',
			filterParams: {
				filterOptions: [
					'contains',
					'equals',
				],
			},
		},
		{
			field: 'msg_simple',
			headerName: 'Message',
			enableRowGroup: true,
			suppressHeaderMenuButton: true,
			filter: 'agTextColumnFilter',
			filterParams: {
				filterOptions: [
					'contains',
				],
			},
		},
		{
			field: 'msg_detailed',
			headerName: 'Detailed MSG',
			enableRowGroup: true,
			suppressHeaderMenuButton: true,
			filter: 'agTextColumnFilter',
			filterParams: {
				filterOptions: [
					'contains',
					'equals',
				],
			},
		},
		{
			field: 'status',
			headerName: 'Status',
			enableRowGroup: true,
			suppressHeaderMenuButton: true,
			filter: 'agTextColumnFilter',
			filterParams: {
				filterOptions: [
					'contains',
					'equals',
				],
			},
		},
		{
			field: 'user_name',
			headerName: 'User',
			enableRowGroup: true,
			suppressHeaderMenuButton: true,
			filter: 'agTextColumnFilter',
			filterParams: {
				filterOptions: [
					'contains',
					'equals',
				],
			},
		},
	]);

	const defaultColDef = useMemo<ColDef>(() => {
		return {
			editable: false,
			enableRowGroup: true,
			enablePivot: true,
			enableValue: true,
			filter: true,
			floatingFilter: true,
			flex: 1,
			minWidth: 50,
			enableCellChangeFlash: true,
		};
	}, []);

	const rowSelected = useCallback(() => {
		const selectedRows = gridRef.current!.api.getSelectedRows();
		const row = selectedRows[0];
		if (!row) {
			return;
		}
		console.warn("Yay! You selected me!", row);
		handleSetSelectedLog(row);

		const opts: GetLogPayload = {
			pageNumber: 1,
			filters: [{
				field: 'quick_link',
				value: `${row.log_type}_${row.log_id}`,
				operator: QueryOperators.Equals
			}],
			pageSize: 100,
			detailed: true,
		};
		getLogs(token, opts)
		.then(res => console.log('did quick link', res))
		.catch(err => {
			console.error(err);
		});
	}, []);

	return(
		<>
			{!!quickLink && urlRef.current && (
				<>
					<div className="bg-danger-subtle rounded-2 d-inline-block d-flex align-items-center flex-shrink-0 mb-3">
						<span className="ms-2 fs-3 fw-bold text-dark">
							Filtered to Quick Link: {quickLink}
						</span>
							<Link
								to={urlRef.current}
								className="text-danger ms-3"
							>
								Clear filter
							</Link>
					</div>
				</>
			)}
			<Grid<LogModel>
				gridRef={gridRef}
				cols={columnDefs}
				defaultColDef={defaultColDef}
				rowModelType={'infinite'}
				rowSelection={'single'}
				cacheBlockSize={100}
				cacheOverflowSize={2}
				maxConcurrentDatasourceRequests={2}
				maxBlocksInCache={2}
				pagination={true}
				gridOptions={gridOptions}
				onRowClicked={rowSelected}
				gridHeight={'400px'}
				gridWidth={'100%'}
			></Grid>
			{selectedLog && (
				<>
					<h2>Details: </h2>
					<CopyToClipboard
						text={fullUrlQuickLink}
						onCopy={() => toast.info(`Copied Link to this!`, {
							autoClose: 500,
						})}
					>
						<Button
							variant="outline-primary"
							type="button"
							>
							<span>
							Copy link to record
								<i
									className="bi bi-copy align-self-center ms-2"
								/>
							</span>
						</Button>
						{/*<span*/}
						{/*	className="d-block p-4 rounded-2 bg-secondary cursor-pointer d-flex text-hover-primary align-content-center justify-content-evenly"*/}
						{/*>*/}
						{/*	<span className="font-monospace text-hover-dark text-dark">*/}
						{/*		{selectedPlaceholder.content}*/}
						{/*	</span>*/}
						{/*	<i*/}
						{/*		className="bi bi-copy align-self-center"*/}
						{/*	/>*/}
						{/*</span>*/}
					</CopyToClipboard>
					{!!selectedLog.log_deep_id && (
						<>
							<CopyToClipboard
								text={fullUrlDeepLogQuickLink}
								onCopy={() => toast.info(`Copied Deep LinK!`, {
									autoClose: 500,
								})}
							>
								<Button
									variant="outline-info"
									type="button"
								>
							<span>
							Copy link to deep log
								<i
									className="bi bi-copy align-self-center ms-2"
								/>
							</span>
								</Button>
								{/*<span*/}
								{/*	className="d-block p-4 rounded-2 bg-secondary cursor-pointer d-flex text-hover-primary align-content-center justify-content-evenly"*/}
								{/*>*/}
								{/*	<span className="font-monospace text-hover-dark text-dark">*/}
								{/*		{selectedPlaceholder.content}*/}
								{/*	</span>*/}
								{/*	<i*/}
								{/*		className="bi bi-copy align-self-center"*/}
								{/*	/>*/}
								{/*</span>*/}
							</CopyToClipboard>
						</>
					)}

					<Form>
						<Form.Check
							type="switch"
							id="log-hide-nulls"
							label="Hide empty values"
							checked={hideFalsy}
							onChange={e => {
								setHideFalsy(!hideFalsy);
								// setFilteredSelectedLog(filterFalsy(selectedLog));
							}}
						/>
					</Form>
					<ReactJson
						src={hideFalsy ? (filteredSelectedLog as object) : selectedLog}
						name={`${selectedLog.log_type} Log #${selectedLog.log_id}`}
						enableClipboard={true}
						displayObjectSize={true}
						sortKeys={true}
					/>
				</>
			)}
		</>
	)
}
