import axios from 'axios'
import {
  GET_USER_BY_ACCESSTOKEN_URL,
  LOGIN_URL,
  REGISTER_URL,
  REQUEST_PASSWORD_URL,
} from '../../../../common/consts/api.const';

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios({
    method: 'post',
    url: LOGIN_URL,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { email, password }
  })
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string, password_confirmation: string) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email
  })
}

export function getUserByToken(token: string) {
  return axios({
    method: 'get',
    url: GET_USER_BY_ACCESSTOKEN_URL,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}
