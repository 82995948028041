import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { KTSVG } from '../../../_library/helpers';
import { useSortableData } from '../../../_library/helpers/sortTable';
import { PageTitle } from '../../../_library/layout/core';
import { SkeDialogDelete } from '../../../common/components/dialog-delete';
import { Company } from '../../../features/company/models/company.model';
import { RootState } from '../../../setup';
import { deleteCompany, getCompanies } from '../../modules/CRUD/CRUD';
import { CreateCompanyModalForCompany } from '../modals/CreateCompanyModalForCompany';
import {Grid} from '../../../common/components/grid';

type Props = {
	className?: string
}

function CompaniesPage({ className }: Props) {
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
	const [allCompanies, setAllCompanies] = useState<Company[]>([]);
	const {
		items,
		requestSort,
		sortConfig,
	} = useSortableData(allCompanies);
	const [selectedCompany, setSelectedCompany] = useState<Company>();
	const [showDeleteCompanyDialog, setShowDeleteCompanyDialog] = useState<boolean>(false);
	const [isVisible, setIsVisible] = useState(false);
	const getClassNamesFor = (name: string) => {
		if (!sortConfig) {
			return;
		}
		return sortConfig.key === name ? sortConfig.direction : undefined;
	};

	const handleConfirmDelete = () => {
		invariant(selectedCompany, 'Cannot delete company as no company is selected');
		deleteCompany(selectedCompany.id, token)
			.then(() => {
				getCompanies(token)
					.then(({ data }) => {
						setShowDeleteCompanyDialog(false);
						setSelectedCompany(undefined);
						setAllCompanies(data.items);
					});
			});
	};

	const handleCancelDelete = () => {
		setShowDeleteCompanyDialog(false);
		setSelectedCompany(undefined);
	}

	useEffect(() => {
		getCompanies(token)
			.then(({ data }) => {
				setAllCompanies(data.items);
				setRowData(data.items);
			});
	}, [token]);



	const [rowData, setRowData] = useState<any>( [] );

	useEffect(() => {
		console.log("Get rowdata: ", rowData)
	}, [rowData]);

	const [columnDefs, setColumnDefs] = useState<any>([
		{
			field: "id",
			headerName: "Make",
			width: 150,
			hide: true
		},
		{
			field: "name",
			headerName: "Name",
			width: 150,
			filter: 'agTextColumnFilter',
			cellStyle: ( params: any ) => {
				return {
					'fontWeight': '500'
				}
			}
		},
		{
			field: "size",
			headerName: "Size",
			width: 50,
			cellStyle: ( params: any ) => {
				return {
					'fontWeight': '500'
				}
			}
		},
		{
			field: "website",
			headerName: "Website",
			width: 50,
			cellStyle: ( params: any ) => {
				return {
					'fontWeight': '500'
				}
			}
		},
		{
			field: "industry",
			headerName: "Industry",
			width: 50,
			cellStyle: ( params: any ) => {
				return {
					'fontWeight': '500'
				}
			}
		},
		{
			field: "country",
			headerName: "Country",
			width: 50,
			cellStyle: ( params: any ) => {
				return {
					'fontWeight': '500'
				}
			}
		},
		{
			field: "settings.connection.attendance.tracking_start",
			headerName: "Attendance Streaks Start",
			width: 50,
			cellStyle: ( params: any ) => {
				return {
					'fontWeight': '500'
				}
			}
		},
		{
			field: "settings.connection.attendance.points.method",
			headerName: "Attendance Rolloff Method",
			width: 50,
			cellStyle: ( params: any ) => {
				return {
					'fontWeight': '500'
				}
			}
		},
		{
			field: "settings.connection.attendance.points.period_unit",
			headerName: "Attendance Rolloff Period",
			width: 50,
			cellStyle: ( params: any ) => {
				return {
					'fontWeight': '500'
				}
			}
		},
		{
			field: "settings.connection.attendance.points.period_count",
			headerName: "Attendance Rolloff Count",
			width: 50,
			cellStyle: ( params: any ) => {
				return {
					'fontWeight': '500'
				}
			}
		},
		{
			field: "settings.sms.expires_at",
			headerName: "SMS Token Expires",
			width: 50,
			cellStyle: ( params: any ) => {
				return {
					'fontWeight': '500'
				}
			}
		},
		{
			field: "settings.sms.phone_number",
			headerName: "SMS Number",
			width: 50,
			cellStyle: ( params: any ) => {
				return {
					'fontWeight': '500'
				}
			}
		},
		{
			field: "actions",
			headerName: "Actions",
			suppressMenu: true,
			suppressSorting: true,
			suppressFilter: true,
			cellRenderer: (params: any) => {
				return <>
					<div className="text-end">
						<Link
							to={`/edit-company/${params.data.id}`}
							className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
						>
							<KTSVG
								path="/media/icons/duotune/general/gen055.svg"
								className="svg-icon-3"
							/>
						</Link>
						<Link
							to="#"
							className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm ms-2"
							onClick={() => {
								setSelectedCompany(params.data);
								setShowDeleteCompanyDialog(true);
							}}
						>
							<KTSVG
								path="/media/icons/duotune/general/gen027.svg"
								className="svg-icon-3"
							/>
						</Link>
					</div>
				</>
			},
		}
	]);

	return (
		<>
			<PageTitle breadcrumbs={[]}>Companies</PageTitle>
			{isVisible && (
				<div>
					<CreateCompanyModalForCompany />
				</div>
			)}
			<div className={`card ${className}`}>
				<div className="card-header border-0 pt-5">

					<div className="pt-7 d-flex w-200px align-items-center"></div>
					<div
						className="card-toolbar"
						data-bs-toggle="tooltip"
						data-bs-placement="top"
						data-bs-trigger="hover"
						title="Click to add a company"
					>
						<div className="d-flex align-items-center py-1">
							<div className="me-4">
							</div>
							<div className="me-4">
								<Link
									to="#"
									onClick={()=> {
										console.warn("THIS IS A TEMPORARY FIX", document.querySelector("#create_company_modal_for_company"))
										setIsVisible(!isVisible)
									}}
									className="btn btn-sm btn-light-primary"
									data-bs-toggle="modal"
									data-bs-target="#create_company_modal_for_company"
								>
									<i className="bi bi-plus svg-icon-5 svg-icon-gray-500 me-1"></i>
									Create
								</Link>
							</div>
						</div>
					</div>
				</div>
				<Grid
					rowData={rowData}
					cols={columnDefs}
					gridHeight={'400px'}
					gridWidth={'100%'}
				></Grid>
			</div>
			{showDeleteCompanyDialog && selectedCompany && (
				<SkeDialogDelete
					onCancel={handleCancelDelete}
					onConfirm={handleConfirmDelete}
					successMessage="Company deleted"
					message={`Are you sure you want to delete ${selectedCompany.name}?`}
				/>
			)}
		</>
	);
}

export default CompaniesPage;
