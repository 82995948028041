import { PartialDeep } from 'type-fest';
import { PermissionSubjectEnum } from '../../permissions/models/permission-subject.model';

export interface LogModel {
	category_id: number | null;
	company_name: string | null;
	created_at: Date | null;
	crud: string | null;
	details?: LogLightDetailed | LogDeepDetailed;
	domain: string | null;
	full_count: number;
	handler: string | null;
	log_count: number;
	log_deep_id: string | null;
	// bigint
	log_id: string;
	log_type: SkeLogType;
	msg_detailed: string | null;
	msg_simple: string | null;
	status: string | null;
	subdomain: string | null;
	tenant_id: string | null;
	tenant_id_new: string | null;
	user_id: number | null;
	user_name: string | null;
	quick_link: string | null;
}

export interface LogLightDetailed {
	context: PartialDeep<LogLightContext> | null;
}

export interface LogDeepDetailed {
	subject: PermissionSubjectEnum;
	// future
	operation: null;
	level: SkeLogLevelEnum;
	// future state
	log_code: {
		log_code_id: number | null;
		log_code_value: string | null;
	} | null;
	http_status_code: number | null;
	metadata: LogDeepMetadata;
	stack:any | null;
	context: PartialDeep<LogLightContext> | null;
}
export enum SkeLogLevelEnum {
	Error = 'ERROR',
	Warn = 'WARN',
	Info = 'INFO',
	Log = 'LOG',
	Verbose = 'VERBOSE',
}

export interface LogDeepMetadata {
	request: LogRequestMetadata;
}

export interface LogRequestMetadata {
	method: SkeHttpMethodEnum;
	query: { [key: string]: string | number };
	params: { [key: string]: string | number };
	base_url: string;
	ip_address: string;
	url: string;
}

export enum SkeHttpMethodEnum {
	Get = 'GET',
	Post = 'POST',
	Patch = 'PATCH',
	Put = 'PUT',
	Delete = 'DELETE',
}

export interface LogLightContext {
	subject_id: number;
	execution: {
		hardware: {
			ip_address: string;
			device: string;
		};
		browser: {
			user_agent: string;
		};
	};
	request: {};
	response: {
		payload_bytes: number;
		items: number;
	};
	misc: {
		request: {
			body: {
				[key: string]: any;
			};
			params: {
				[key: string]: any;
			};
		};
		response: {
			body: {
				[key: string]: any;
			};
		};
		snapshot: {
			old: {
				[key: string]: any;
			};
			new: {
				[key: string]: any;
			};
		};
	};
}

export type LogSortableFields =
	| 'user_name'
	| 'domain'
	| 'subdomain'
	| 'handler'
	| 'crud'
	| 'status'
	| 'company_name'
	| 'created_at';

export type LogFilterableFields =
	| 'tenant_id'
	| 'company_name_search'
	| 'user_id'
	| 'user_name_search'
	| 'before_date'
	| 'after_date'
	| 'type'
	| 'domain'
	| 'subdomain'
	| 'action'
	| 'status'
	| 'msg_simple'
	| 'msg_detailed'
	| 'quick_link'

export enum SkeCrudTypeFromAbbr {
	C = 'CREATE',
	R = 'READ',
	U = 'UPDATE',
	P = 'DELETE',
}

// from API
export enum SkeCrudType {
	Create = 'CREATE',
	Read = 'READ',
	Update = 'UPDATE',
	Delete = 'DELETE',
}

export enum SkeCrudAbbr {
	Create = 'C',
	Read = 'R',
	Update = 'U',
	Delete = 'D'
}

export enum SkeLogType {
	Light = 'LIGHT',
	// future
	// Standard = 'STANDARD',
	Deep = 'DEEP',
}
